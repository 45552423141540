*,
*:before,
*:after {
  box-sizing: border-box;
  outline: none;
}
code,
.card .line-numbers {
  font-family: sans-serif;
  font-size: 15px;
}
.card {
  max-width: 550px;
  position: relative;
  height: 17rem;
  perspective: 150rem;
  margin: auto;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}
.card-front,
.card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  border-radius: 5px;
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.4);
  transition: 0.9s cubic-bezier(0.25, 0.8, 0.25, 1);
  backface-visibility: hidden;
  overflow: hidden;
}
.card-front:before,
.card-front:after,
.card-back:before,
.card-back:after {
  position: absolute;
}
.card-front:before,
.card-back:before {
  top: -40px;
  right: -40px;
  content: "";
  width: 80px;
  height: 80px;
  background-color: rgba(255, 255, 255, 0.08);
  transform: rotate(45deg);
  z-index: 1;
}
.card-front:after,
.card-back:after {
  content: "+";
  top: 0;
  right: 10px;
  font-size: 24px;
  transform: rotate(45deg);
  z-index: 2;
}
.card-front {
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, #101010, #2c3e50);
}
.card-front:after {
  color: #212f3c;
}
.card-back {
  background: linear-gradient(-45deg, #101010, #2c3e50);
  transform: rotateX(180deg);
}
.card-back:after {
  color: #11181f;
}
/* .card:hover .card-front {
  transform: rotateX(-180deg);
} 
.card:hover .card-back {
  transform: rotateX(0deg);
} */
.card .line-numbers {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 0;
  padding: 0 10px;
  background-color: rgba(255, 255, 255, 0.03);
  font-size: 13px;
}
.card .line-numbers > div {
  padding: 2.5px 0;
  opacity: 0.15;
}
.card code,
.card .line-numbers {
  color: whitesmoke;
}
.card .indent {
  padding-left: 30px;
}
.card .operator {
  color: #4dd0e1;
}
.card .string {
  color: #9ccc65;
}
.card .variable {
  color: #ba68c8;
}
.card .property {
  color: #ef5350;
}
.card .method {
  color: #29b6f6;
}
.card .function {
  color: #fdd835;
}
.card .boolean {
  color: #4dd0e1;
}
@media only screen and (max-width: 600px) {
  .card .line-numbers {
    font-size: 10px;
    padding: 0px 5px;
  }
  code {
    font-size: 0.85rem;
    margin-left: 15px;
    line-height: 21px;
  }
  .card .indent {
    padding-left: 20px;
  }
}
